<template>
  <div class="home-page">
    <div class="home-page_banner">
      <!-- banner -->
      <el-carousel
        class="banner_carousel"
        arrow="never"
        :loop="false"
        :interval="300000"
        indicator-position="none"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image :src="item" style="width: 100%; height: 100%"></el-image>
        </el-carousel-item>
      </el-carousel>
      <div class="banner_box d-flex-center">
        <div class="banner_title">
          Trusted<span style="color: #1d2129">GPT</span>
        </div>
        <div class="banner_desc">自研可信大模型评测平台</div>
        <div class="banner_btn">
          <el-button type="primary" @click="jumpLeaderBoard"
            >查看榜单<i class="el-icon-arrow-right el-icon--right"></i
          ></el-button>
        </div>
      </div>
    </div>

    <!-- 最新动态 -->
    <div class="home-page_news d-flex-center">
      <img class="news-img" src="@/assets/images/home/icon_1.png" />
      <div class="title">最新动态：</div>
      <div class="desc">10月22日通用能力榜单更新</div>
    </div>

    <!-- 大模型评测平台介绍 -->
    <div class="home-page_introduce d-flex-center container">
      <div class="main-title">大模型评测平台介绍</div>

      <div class="main-desc">
        TrustedGPT
        是一个自研可信大模型评测平台，致力于为大模型提供全面、客观的性能评估。
        我们覆盖了 {{ model_number }} 个国内外主流的闭源和开源大模型，通过
        {{ demension_number }} 多个细粒度的评测维度，以及
        {{ dataset_number }}
        个评测数据集，在多种任务类型上对大模型的可信能力进行全面测试。目前，平台已收集
        {{ test_data_number }}
        条高质量测评数据，支持中英双语评测，确保评测结果的准确性和可靠性。
      </div>

      <div class="introduc-list">
        <div
          v-for="(item, index) in introduceList"
          :key="index"
          class="item d-flex-center"
        >
          <el-image class="introduce-img" :src="item.img"></el-image>
          <div class="text">{{ item.text }}</div>
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>

    <!-- 垂直榜单 -->
    <div class="home-page_rank d-flex-center container">
      <div class="title">垂类榜单</div>
      <div class="desc">
        垂类榜单专注于特定领域的大模型性能评测，旨在为不同行业的专业人士提供一个深入了解和比较各个模型在特定领域内表现的窗口。通过这些榜单，用户可以快速识别出在不同垂直领域中表现最佳的大模型。目前，我们公布了中医领域和金融领域的大模型排行榜。在未来，我们将定期更新榜单，并探索其他领域的大模型能力测评。
      </div>

      <div class="introduc-list">
        <div
          v-for="(item, index) in rankList"
          :key="index"
          class="item d-flex-center"
          @click="jumpDetail(item)"
        >
          <div class="rank-img-box">
            <el-image class="rank-img" :src="item.img"></el-image>
          </div>

          <div class="rank_box">
            <div class="rank_desc">{{ item.desc }}</div>
            <div class="rank_bottom">
              <div class="rank_high-text">查看详情</div>
              <div class="rank_time d-flex">
                <i class="el-icon-time"></i>
                <div class="rank_value">{{ item.time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- banner1  -->
    <el-image class="home-page_box-banner1" :src="boxBanner1"></el-image>

    <!-- 维度介绍 -->
    <div class="home-page_dimension container">
      <div class="title">自研可信大模型评测平台TrustedGPT</div>
      <div class="desc">
        TrustedGPT
        集成了一系列评测支撑工具，包括数据搜集与管理工具、模型部署与推理工具以及模型输出评估工具，旨在提供对大模型性能与可信度的全方位评估。平台提供了全面的评测服务，包括评测展示、评测排行榜、评测报告和评测社区。我们通过专业的评测维度，如金融、医疗、法律等，来评估模型的能力可靠性、输出可信度、和安全可控程度。
      </div>

      <el-image class="home-page_box-banner1" :src="boxBanner2"></el-image>
    </div>

    <!-- 数据集介绍 -->

    <div class="home-page_dataset">
      <el-image class="dataset_img" :src="dataSet"></el-image>

      <div class="right-box">
        <div class="title">数据集介绍</div>
        <div class="desc">
          目前平台收集了
          {{
            dataset_number
          }}
          个评测数据集，涵盖了各种来源和任务类型。这些数据集包括来自开源社区（GitHub、Hugging
          Face等）的高质量数据集，以及我们的自建数据集，为大模型的评测提供了坚实的基础。通过这些数据集，我们可以全面评估模型在不同场景下的表现，从而为用户提供可靠的评测结果。
        </div>

        <el-button type="primary" class="btn" @click="jumpDataset"
          >查看详情<i class="el-icon-arrow-right el-icon--right"></i
        ></el-button>
      </div>
    </div>

    <!-- 模型介绍 -->
    <div class="home-page_model">
      <div class="right-box">
        <div class="title">模型介绍</div>
        <div class="desc">
          在TrustedGPT，我们致力于涵盖国内外最先进的各种大模型。目前，我们已经收录了包括
          ChatGPT、Qwen、智谱AI、Erine 在内的
          {{ model_number }}
          个顶尖模型。每个模型的介绍都包含了其核心特点、应用场景和性能表现，帮助用户更好地理解和选择适合自己需求的模型。
        </div>

        <el-button type="primary" class="btn" @click="jumpModel"
          >查看详情<i class="el-icon-arrow-right el-icon--right"></i
        ></el-button>
      </div>
      <el-image class="dataset_img" :src="modelImg"></el-image>
    </div>
    <!-- 底部联系栏目 -->
    <div class="home-page_footer">
      <div class="left-box">
        <div class="top-content">
          <img class="left" src="@/assets/images/logo.png" />

          <div class="right">
            <div class="title">可信大模型评测平台</div>
            <div class="desc">
              Self-developed Reliable Large Model Evaluation Platform
            </div>
          </div>
        </div>
        <div class="bottom-content">
          一个自研可信大模型评测平台，致力于为大模型提供全面、客观的性能评估。
        </div>
      </div>
      <div class="right-box">
        <div class="contact">
          <div class="title">联系我们</div>
          <div class="email">邮箱：TrustedGPT@mail.sysu.edu.cn</div>
          <!-- <div class="phone">联系电话：400 890 941</div> -->
          <div class="time">工作时间：9:00 - 17:00</div>

          <div class="time">
            快速链接：<a
              href="https://sse.sysu.edu.cn/"
              class="text"
              target="_blank"
              >中山大学软件工程学院</a
            >
          </div>
        </div>
        <div class="wechat">
          <div class="title">微信公众号</div>
          <el-image class="qrcode" :src="qrcode"></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner1 from "@/assets/images/home/head.png";
import boxBanner1 from "@/assets/images/home/box_banner1.png";
import boxBanner2 from "@/assets/images/home/box_banner2.png";

import dataSet from "@/assets/images/home/dataSet.png";
import modelImg from "@/assets/images/home/model_img.png";
import qrcode from "@/assets/qrcode.jpg";

export default {
  data() {
    return {
      // model_number: "30+", //模型数量
      // demension_number: "40+", //维度数量
      // dataset_number: "100+", //数据集个数
      // test_data_number: "50万+", //测试数据个数
      model_number: "120",
      demension_number: "57",
      dataset_number: "79",
      test_data_number: "209万",
      bannerList: [banner1],
      boxBanner1,
      boxBanner2,
      dataSet: dataSet,
      modelImg: modelImg,
      qrcode: qrcode,
      dimensionList: [],
      modelList: [],

      rankList: [
        {
          img: require("@/assets/images/home/rank_6.png"),
          text: "中医排行榜(CTCMB)",
          desc: "中医排行榜通过专业的评测维度，评估模型在中医知识理解和应用方面的能力。让大模型探索中医领域的智慧，为传统医学的现代化提供技术支持。",
          time: "2024-09-25",
          path: "CTCMB",
        },
        // {
        //   img: require("@/assets/images/home/rank_1.png"),
        //   text: "医疗排行榜（Med）",
        //   desc: "医疗排行榜专注于评估大模型在医疗健康领域的应用能力，从数据理解到诊断建议，我们的评测维度覆盖了医疗行业的多方面，确保模型输出准确性和可靠性。",
        //   time: "2024-09-25",
        //   path: "med",
        // },
        // {
        //   img: require("@/assets/images/home/rank_2.png"),
        //   text: "机器人排行榜（Robot）",
        //   desc: "机器人排行榜旨在评估大模型在机器人技术中的应用，包括自然语言处理、决策制定和环境适应性等关键能力，推动机器人技术的智能化发展。",
        //   time: "2024-09-25",
        //   path: "robot",
        // },
        {
          img: require("@/assets/images/home/rank_3.png"),
          text: "金融排行榜（Finance）",
          desc: "金融排行榜专注于金融领域的大模型评测，从风险评估到市场分析，我们力求让评测维度全面覆盖金融行业的各个方面，为金融决策提供强有力的数据支持。",
          time: "2024-09-25",
          path: "finance",
        },
        // {
        //   img: require("@/assets/images/home/rank_4.png"),
        //   text: "硬件排行榜（Hardware）",
        //   desc: "硬件排行榜评估大模型在硬件设计和优化中的应用，为硬件行业的创新和发展提供技术支持。",
        //   time: "2024-09-25",
        //   path: "hardware",
        // },
        // {
        //   img: require("@/assets/images/home/rank_5.png"),
        //   text: "代码排行榜（Code）",
        //   desc: "代码排行榜专注于评估大模型在编程领域的能力，从代码生成到漏洞检测和软件维护，我们的评测维度旨在推动编程技术的自动化和智能化发展。",
        //   time: "2024-09-25",
        //   path: "code",
        // },
      ],
      introduceList: [
        {
          img: require("@/assets/images/home/introduce_img1.png"),
          text: "",
          title: "主流大模型",
          desc: "覆盖国内外主流闭源大模型 和开源大模型",
        },
        {
          img: require("@/assets/images/home/introduce_img2.png"),
          text: "",
          title: "评测维度",
          desc: "覆盖大模型可信能力有关 的40多个细粒度维度",
        },
        {
          img: require("@/assets/images/home/introduce_img3.png"),
          text: "",
          title: "评测数据集",
          desc: "该来源多样、任务类型丰富、评介 指标各异的100多个评测数据集",
        },
        {
          img: require("@/assets/images/home/introduce_img4.png"),
          text: "",
          title: "评测数据",
          desc: "包含高质量、人工创建或 自动收集的评测数据",
        },
        {
          img: require("@/assets/images/home/introduce_img5.png"),
          text: "中英双语",
          title: "评测维度",
          desc: "对中文能力和英文能力 同时进行评测",
        },
      ],
    };
  },
  created() {
    // init 测评数据数量
    this.introduceList[0].text = this.model_number;
    this.introduceList[1].text = this.demension_number;
    this.introduceList[2].text = this.dataset_number;
    this.introduceList[3].text = this.test_data_number;
  },
  // mounted() {
  //   // this.axios.get("static/dimension/dimension.json").then((response) => {
  //   //   console.log(response);
  //   //   this.dimensionList = response.data.dimensions;
  //   // });
  //   // this.axios.get("static/model/organization.json").then((response) => {
  //   //   console.log(response);
  //   //   this.modelList = response.data.organizations;
  //   // });
  // },

  methods: {
    jumpLeaderBoard() {
      localStorage.setItem("leaderboardName", "大语言模型榜单");
      localStorage.removeItem("leaderboardPath");
      this.$router.push("/leaderboard");
    },
    jumpDataset() {
      this.$router.push({ name: "dataset" });
    },
    jumpModel() {
      this.$router.push({ name: "model" });
    },
    jumpDetail(item) {
      // 中医特殊处理
      if (item.path == "CTCMB") {
        this.$router.push({
          name: "CTCMB",
          params: { path: item.path, name: item.text },
        });
      } else if (item.path == "finance") {
        this.$router.push({
          name: "FIN",
          params: { path: item.path, name: item.text },
        });
      } else {
        localStorage.setItem("leaderboardPath", item.path);
        localStorage.setItem("leaderboardName", item.text);
        this.$router.push({
          name: "leaderboard",
          params: { path: item.path },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// element reset css
::v-deep .el-carousel__indicators--horizontal {
  padding: 12px 10px;
  bottom: 100px;
}

::v-deep .el-carousel__button {
  width: 48px;
  height: 3px;
  background-color: #165dff;
}

::v-deep .el-carousel__container {
  height: 100%;
}
// end
.home-page {
  background: #fff;

  .home-page_footer {
    background-color: #f7f8fa;
    padding: 24px 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-box {
      .top-content {
        display: flex;
        align-items: center;
        .left {
          width: 85px;
          height: 24px;
          // border-radius: 8px;
          margin-right: 16px;
        }
        .right {
          display: flex;
          flex-direction: column;
          .title {
            font-weight: 500;
            font-size: 24px;
            color: #222222;
            margin-bottom: 4px;
            text-align: left;
          }
          .desc {
            text-align: left;
            font-weight: 300;
            font-size: 10px;
            color: #1d2129;
          }
        }
      }
      .bottom-content {
        width: 560px;
        height: 60px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #4e5969;
        line-height: 20px;
        margin-top: 16px;
        text-align: left;
      }
    }
    .right-box {
      display: flex;

      .contact {
        text-align: left;
        display: flex;
        flex-direction: column;
        margin-right: 120px;
        .title {
          font-weight: 500;
          font-size: 14px;
          color: #1d2129;
        }
        .email,
        .phone,
        .time {
          font-weight: 400;
          font-size: 14px;
          color: #4e5969;
          margin-top: 12px;
        }
      }
      .wechat {
        display: flex;
        align-items: center;
        flex-direction: column;
        .title {
          font-weight: 500;
          font-size: 14px;
          color: #1d2129;
        }
        .qrcode {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .home-page_dataset,
  .home-page_model {
    display: flex;
    align-content: center;
    justify-content: space-between;
    background: #f7f8fa;
    padding: 59px 120px;
    .dataset_img {
      width: 624px;
      height: 624px;
      flex-shrink: 0;
    }
    .right-box {
      margin-left: 160px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        height: 56px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 48px;
        color: #1d2129;
        line-height: 56px;
        text-align: left;
        margin-bottom: 24px;
      }
      .desc {
        height: 96px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #4e5969;
        line-height: 24px;
        text-align: left;
        margin-bottom: 60px;
      }
      .btn {
        width: 124px;
        height: 44px;
      }
    }
  }
  .home-page_model {
    background-color: #fff;
    .right-box {
      margin-right: 160px;
    }

    .dataset_img {
      width: 512px;
      height: 512px;
      flex-shrink: 0;
    }
  }
  .home-page_banner {
    position: relative;
    height: 900px;
  }
  .banner_carousel {
    height: 100%;
  }

  .banner_box {
    position: absolute;
    z-index: 100;
    top: 40%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    flex-direction: column;
    .banner_title {
      font-weight: 900;
      font-size: 110px;
      color: #165dff;
      width: 100%;
      height: 100px;
    }
    .banner_desc {
      width: 100%;
      font-weight: 400;
      font-size: 32px;
      height: 100px;

      color: #4e5969;
    }
    .banner_btn {
      width: 160px;
      height: 48px;
    }
  }
  .home-page_news {
    height: 48px;
    background: #f7f8fa;
    .news-img {
      width: 24px;
      height: 24px;
    }
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
      margin-right: 24px;
    }
    .desc {
      font-weight: 400;
      font-size: 16px;
      color: #4e5969;
    }
  }
  .home-page_introduce {
    background: #fff;
    flex-direction: column;
    .main-title {
      font-weight: 500;
      font-size: 48px;
      color: #4e5969;
      margin-top: 100px;
      line-height: 56px;
      height: 56px;
    }
    .main-desc {
      width: 1200px;
      margin-top: 48px;
      font-weight: 400;
      font-size: 16px;
      color: #4e5969;
    }
    .introduc-list {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 48px;

      .item {
        flex: 1 0;
        border-radius: 12px;
        border: 1px solid #dfe1e5;
        padding: 28px 25px;
        flex-direction: column;
        box-sizing: border-box;
        .introduce-img {
          width: 180px;
          height: 207.42px;
          flex-shrink: 0;
        }
        .text {
          font-family: YouSheBiaoTiHei;
          font-weight: 500;
          font-size: 40px;
          color: #1d2129;
          margin-top: 32px;
        }
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #4e5969;
          margin-top: 24px;
        }
        .desc {
          margin-top: 24px;
          font-weight: 400;
          font-size: 16px;
          color: #86909c;
          width: 240px;
        }
      }

      .item:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
  .home-page_rank {
    background: #fff;
    flex-direction: column;

    .title {
      font-weight: 500;
      font-size: 48px;
      color: #1d2129;
      margin-top: 100px;
      line-height: 56px;
      height: 56px;
    }
    .desc {
      width: 1200px;
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      color: #4e5969;
    }
    .introduc-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 48px;
      justify-content: center;
      .item {
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid #e5e6eb;
        margin-bottom: 54px;
        width: 538px;
        height: 100%;
        box-sizing: border-box;
        cursor: pointer;

        .rank_box {
          padding: 16px;
        }

        .rank-img-box {
          position: relative;
          .rank-img {
            width: 538px;
            height: 140px;
          }
        }

        .rank_bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .rank_desc {
          font-weight: 400;
          font-size: 14px;
          color: #4e5969;
          width: 100%;
          text-align: left;
          margin-bottom: 16px;
        }
        .rank_high-text {
          font-weight: 500;
          font-size: 14px;
          color: #165dff;
          cursor: pointer;
        }
        .rank_time {
        }
        .rank_value {
          font-weight: 400;
          font-size: 14px;
          color: #86909c;
          margin-left: 8px;
        }
      }
      .item:hover {
        border: 1px solid #165dff;
      }

      .item:not(:nth-child(3n)) {
        margin-right: 24px;
      }
    }
  }
}

.home-page_dimension {
  background: #fff;
  flex-direction: column;
  margin: 100px 0;
  .title {
    font-weight: 500;
    font-size: 48px;
    color: #1d2129;
    height: 56px;
    line-height: 56px;
    margin-bottom: 24px;
  }
  .desc {
    font-weight: 500;
    font-size: 24px;
    color: #4e5969;
    margin: 0 auto;
    height: 100%;
    margin-bottom: 48px;
    line-height: 1.2;
  }
}
</style>
